<template>
    <div class="container">
        <div class="form-container">
            <h3>{{Department.Name}}</h3>
            <div class="form-page">
                <!-- <div class="form-page-calc-container"> -->
                <!--     <div class="form-page-calculator-container"> -->
                <!--         <div class="form-page-calculator"> -->
                <!--             <div class="form-page-calculator-header"> -->
                <!--             </div> -->
                <!--             <div class="form-page-calculator-body"> -->
                <!--             </div> -->
                <!--         </div> -->
                <!--     </div> -->
                <!-- </div> -->

                <div class="form-page-container-plan">
                    <div class="form-page-container-plan-title">プラン </div>
                    <hr />
                    <div class="form-page-container-plan-what">{{planName}}</div>
                    <div class="plans-func-containe">
                        <ul>
                            <template v-for="funcText in $tm('plans.' + planName + '.funcList')">
                                <li>
                                    <img src="@/assets/check.png" style="height: 16px;" />
                                    <span>{{$rt(funcText)}}</span>
                                </li>
                            </template>
                        </ul>
                    </div>
                    <ul>
                        <li>
                        </li>
                    </ul>
                </div>
                <div class="form-page-container">
                    <div>
                        <div class="form-section">
                            <div class="form">
                            </div>
                        </div>
                        <div class="form-section">
                            <h3>情報</h3>
                                <div v-if="$route.query.showStripe == 'true'">
                                    <InputSwitch v-model="isYear" />  
                                    <label v-if="isYear">year</label> 
                                    <label v-else>month</label> 
                                </div>

                                <div >
                                    <h3 class="invoice-title"> 請求概要 </h3>
                                    <table class="invoice-table" >
                                        <tbody>
                                            <tr>
                                                <td>{{price + $t("plans.currency")}}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                            </tr>
                                        </tfoot>

                                    </table>

                                    <!-- <table class="invoice-table" > -->
                                    <!--     <tbody> -->
                                    <!--         <tr> -->
                                    <!--             <td>{{price + $t("plans.currency")}} × {{groupUserCount + $t("plans.people")}}</td> -->
                                    <!--             <td>{{ fee + (isYear ? $t("plans.priceYearUnit") : $t("plans.priceUnit")) }} </td> -->
                                    <!--         </tr> -->
                                    <!--     </tbody> -->
                                    <!--     <tfoot> -->
                                    <!--         <tr> -->
                                    <!--             <td>合計</td> -->
                                    <!--             <td>{{ fee }} </td> -->
                                    <!--         </tr> -->
                                    <!--     </tfoot> -->
                                    <!--  -->
                                    <!-- </table> -->
                                </div>

                            <div class="form p-mt-2" v-if="$route.query.showStripe == 'true'">
                                <Button class="p-button-outlined p-button-info" @click="onPayPlanClick" > pay </Button>
                                <!-- <StripeForm :subscriptioned="subscriptioned" :departmentId="departmentId" :priceId="priceId" :Email="Email" :departmentName="departmentName" /> -->
                            </div>
                            <div class="form" v-else>
                                <Button class="p-button-outlined p-button-info" @click="onCancelPlanClick" > change </Button>
                                <Dialog :header="$t('plans.freeConfirm1')" v-model:visible="displayFreeConfirmDialog" style="width: 40%">
                                    <div class="p-grid">
                                        <div class="p-col-12" style="font-weight: bold; font-size: 1.1rem;" >
                                            - {{$t('plans.freeConfirmList1')}}
                                        </div>
                                        <div class="p-col-12" style="font-weight: bold; font-size: 1.1rem;">
                                            - {{$t('plans.freeConfirmList2')}}
                                        </div>
                                        <div class="p-col-12" style="font-weight: bold; font-size: 1.1rem;">
                                            - {{$t('plans.freeConfirmList3')}}
                                        </div>
                                        <span class="p-col-12" style="font-size: 0.8rem;">
                                            {{$t('plans.freeConfirmAs1')}}
                                        </span>
                                        <span class="p-col-12" style="font-size: 0.8rem;">
                                            {{$t('plans.freeConfirmAs2')}}
                                        </span>
                                        <span class="p-col-12" style="font-size: 0.8rem;">
                                            {{$t('plans.freeConfirmAs3')}}
                                        </span>

                                        <div class="p-col-12 p-grid  p-jc-between">
                                            <Button  class="p-button-outlined p-button-info" @click="displayFreeConfirmDialog=false" style="margin-left: 1rem;">{{$t("utils.cancel")}}</Button>
                                            <Button  class="p-button-outlined p-button-info" @click="cancelPlan" > {{$t("utils.ok")}} </Button>
                                        </div>
                                    </div>
                                </Dialog>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <template v-if="showSpinner">
        <div class="progressSpinnerContainer" style="" 
                    @showSpinnerEmit="showSpinnerEmit($event)"
            >
            <ProgressSpinner />
        </div>
    </template>
</template>

<script>
import StripeForm from "@/components/stripe"
import {loadStripe} from '@stripe/stripe-js';
import Button from 'primevue/button';
import http from '@/mixins/api';
import InputSwitch from 'primevue/inputswitch';
import ProgressSpinner from 'primevue/progressspinner';
import Dialog from 'primevue/dialog';



export default {
    name: 'Upgrade',
    components: {
        StripeForm,
        Button,
        InputSwitch,
        ProgressSpinner,
        Dialog,
    },
    data: function() {
        return {
            Department: {},
            departmentId: "",
            priceId: "",
            productId: "",
            planName: "",
            isYear: true,
            allPrices: [],
            price: 0,
            base_price: 0,
            fee: 0,
            showSpinner: false,
            groupUserCount: 0,
            Email: "",
            displayFreeConfirmDialog: false,
            subscriptioned: false,
        }
    },

    async beforeRouteEnter(route, redirect, next) {
        const query = route.query
        const departmentId = route.params.departmentId
        const productId = route.params.productId
        const res = await http.post("/customer/SubcriptionDepartment", {DepartmentId: departmentId, productId: productId})
        console.log(res)
        if(res.Success) {
            const res_2 = await http.post('/customer/GetCustomerPortal', {departmentId: departmentId, returnUrl: local + "/admin/changeRegistrationInfo" });
            console.log(res_2)
            if(res_2.Success) {
                location.href = res_2.portalSessionURL
            }
            // next(vm => vm.setData({subscription: res.Success, departmentId: departmentId, planName: query.planName, productId: productId, groupUserCount: res.GroupUserCount, departmentEmail: res.DepartmentEmail}))
            next(false)
        } else {
            next(vm => vm.setData({subscription: res.Success, departmentId: departmentId, planName: query.planName, productId: productId, groupUserCount: res.GroupUserCount, departmentEmail: res.DepartmentEmail}))
        }
    },

    watch: {
        isYear: function() {
            this.setPrices()
        }
    },

    methods: {
        setData: async function(data) {
            this.subscriptioned = data.subscription
            const departmentId = data.departmentId
            const DepartmentList = this.$store.getters["userBranchInfo/getDepartmentList"]
            const department = DepartmentList.find(x => x._id == departmentId)
            this.planName = data.planName
            this.Department = department
            this.Email = data.departmentEmail
            console.log(data)
            this.productId = data.productId
            this.priceId = this.$route.params.priceId
            this.departmentId = this.$route.params.departmentId
            this.departmentName = department.Name
            if(data.groupUserCount) {
                this.groupUserCount = data.groupUserCount
            }
            const res = await http.post("/customer/GetPricesByProductId", {productId: this.productId})
            console.log(res)
            if(res.Success) {
                const allPrices =  res.allPrices
                this.allPrices = allPrices
                this.setPrices()
            }
        },

        setPrices: function() {
            const allPrices = this.allPrices
            const type = this.isYear ? "year" : "month"
            const prices = allPrices.find(x => x.metadata.type == type)
            console.log(prices)
            if(prices) {
                // this.base_price = prices.tiers[0].unit_amount
                // this.price = prices.tiers[0].up_to
                // this.base_price = prices.tiers[0].flat_amount_decimal ? prices.tiers[0].flat_amount_decimal.replace(/([1-9].*)([0-9]{2})/, "$1.$2") : null
                this.price = prices.unit_amount ? prices.unit_amount_decimal.replace(/([1-9].*)([0-9]{2})/, "$1.$2") : null;
                this.priceId = prices.id
                // this.fee = (prices.tiers[0].unit_amount * this.groupUserCount).toString().replace(/([1-9].*)([0-9]{2})/, "$1.$2")
            }
        },

        onCancelPlanClick: function() {
            this.displayFreeConfirmDialog = true
        },

        cancelPlan: async function() {
            this.displayFreeConfirmDialog = false
            const res = await http.post("/customer/CancelSubscription", {DepartmentId: this.departmentId})
            console.log(res)
            if(res.Success) {
                this.$toast.add({severity:'success', summary: "success", life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                this.$router.push({ path: `/admin/changeRegistrationInfo`});
            } else {
                this.$toast.add({severity:'error', summary: res.Message ? res.Message : "error", life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
            

        },

        showSpinnerEmit: async function(show) {
            this.showSpinner = show
        },

        onPayPlanClick: async function()  {
            const res = await http.post('/customer/GetCheckoutURL', {departmentId: this.departmentId, returnUrl: location.href, priceId: this.priceId});
            if(res.Success) {
                location.href = res.url
            }

        },
    }
}
</script>

<style scoped>
.container {
    min-width: 900px;
    max-width: 1040px;
}
.form-container {

}
.form-page {
    display: flex;
    justify-content: space-between;
    padding: 48px 24px 0 24px;
}
.form-page-container {
    width: calc(100% - 450px);
    max-width: 500px;
    display: inline-block;
}
.form-page-calc-container {
    width: 425px;
}

.form-section {
    h3 {
        margin-top: 20px;
        font-size: 18px;
        line-height: 1.33334;
        font-weight: 900;
        color: #000;
        font-weight: 400;
    }
    .form {

    }
}
.form-page-calculator-container {
    position: sticky;
    top: 94px;
    border: 1px solid;
}
.form-page-calculator {
    box-shadow: 0 5px 15px 0 rgb(0 0 0 / 10%);
    background-color: rgba(var(--sk_primary_background,255,255,255),1);
}
.form-page-calculator-header {
    padding: 32px 36px 28px 36px;
    background-color: #4a154b;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.form-page-calculator-body {
    position: relative;
    padding: 36px;
    transition: opacity .3s;
    opacity: 1;
}
.form-page-container-plan {
    background: #F4F5F7;
    border-radius: 8px;
    padding: 20px 24px;
    margin-right: 47px;
    width: 260px;
}
.form-page-container-plan-title {
    font-weight: 500;
    font-size: 24px;
}
hr {
    background-color: #091e4221;
    border: 0;
    height: 1px;
    margin: 16px 0;
    padding: 0;
    width: 100%;
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.form-page-container-plan li {
    display: flex;
    list-style: none;
    margin: 0;
    line-height: 20px;
    margin-bottom: 16px;
}
.plans-func-containe {
    border-top: 1px solid rgb(223, 225, 230);
    height: auto;
    padding-top: 1.25rem;
}
.plans-func-containe ul {
    font-size: 1rem;
    list-style-type: none;
    margin: 0px;
    padding: 0px 0px 0px 1.5rem;
}
.plans-func-containe li {
    text-align: left;
    margin: 0px 0px 0.5rem;
    position: relative;
}
.invoice-title {
    color: #172b4d;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.006em;
    line-height: 20px;
    margin-top: 24px;
    margin: 30px 0 20px;
    /* display: flex; */
}
.invoice-table ,
.invoice-table td {
    border: none;
    vertical-align: top;
}
.invoice-table tfoot {
    border-top: 1px solid #DFE1E6;
    font-size: 14px;
    display: table-footer-group;
    vertical-align: middle;
    border-color: inherit;
}
.invoice-table tfoot td{
    font-size: 14px;
    font-weight: 600;
    padding: 10px 0 0 0;
}
.invoice-table td:nth-child(2) {
    text-align: right;
    width: 200px;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
</style>
